<template>
  <div class="local-stream">
    <div v-if="showControls" class="local-stream-overlay" @mouseleave="toggleControlsOnDesktop(false)" @click.self="toggleControlsOnMobile(false)">
      <div v-if="!manageAV" class="local-media-options">
        <div class="float-left">
          <b-button v-if="!localMedia.fullscreen" variant="link" v-b-tooltip.hover title="Fullscreen" @click="toggleFullscreen(true)">
            <BIconFullscreen class="text-white" font-scale="1.2"/>
          </b-button>
          <b-button v-if="localMedia.fullscreen" variant="link" v-b-tooltip.hover title="Exit Fullscreen" @click="toggleFullscreen(false)">
            <BIconFullscreenExit class="text-white" style="padding-top: 1px;"/>
          </b-button>
          <b-button v-if="platform === 'desktop' && !localMedia.screenshare" variant="link" v-b-tooltip.hover title="Share screen" @click="toggleScreenshare(true)">
            <BIconDisplay class="text-white" font-scale="1.45"/>
          </b-button>
          <b-button v-if="platform === 'desktop' && localMedia.screenshare" variant="link" v-b-tooltip.hover title="Stop screen sharing" @click="toggleScreenshare(false)" style="position: relative;">
            <div style="position: absolute; left: 13px; top: 5px;"><BIconX class="text-white" font-scale="1.2"/></div>
            <BIconDisplay class="text-white" font-scale="1.45"/>
          </b-button>
          <b-button v-if="platform === 'desktop'" variant="link" v-b-tooltip.hover title="Minimize" @click="minimize()">
            <BIconPip class="text-white" font-scale="1.45"/>
          </b-button>
        </div>
        <div class="float-right">
          <b-dropdown v-if="platform === 'desktop'" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <BIconThreeDotsVertical class="text-white"/>
            </template>
            <!--
            <b-dropdown-item v-if="!localMedia.blur" @click="localMedia.blur = true">Blur my background</b-dropdown-item>
            <b-dropdown-item v-if="localMedia.blur" @click="localMedia.blur = false">Unblur my background</b-dropdown-item>
            -->
            <b-dropdown-item @click="manageAV = true">Audio/Video Settings</b-dropdown-item>
          </b-dropdown>
          <div v-if="platform === 'mobile'">
            <b-button v-if="localMedia.facingMode === 'user'" variant="link" @click="flipCamera('environment')">
              <BIconBack class="text-white" font-scale="1.45"/>
            </b-button>

            <b-button v-if="localMedia.facingMode === 'environment'" variant="link" @click="flipCamera('user')">
              <BIconFront class="text-white" font-scale="1.45"/>
            </b-button>
          </div>
        </div>
      </div>
      <ul v-if="!manageAV" class="local-controls">
        <li class="mr-5">
          <b-form-checkbox switch size="lg" v-model="localMedia.camera" @change="toggleCamera" :disabled="localMedia.screenshare">
            <BIconCameraVideoOff v-if="!localMedia.camera"/>
            <BIconCameraVideo v-if="localMedia.camera"/>
          </b-form-checkbox>
        </li>
        <li class="mr-5">
          <button class="btn btn-danger btn-call-control-hangup" @click="hangup()"><BIconTelephoneFill rotate="135" style="margin-top: 5px;"/></button>
        </li>
        <li>
          <b-form-checkbox switch size="lg" v-model="localMedia.mic" @change="toggleMic">
            <BIconMicMute v-if="!localMedia.mic"/>
            <BIconMic v-if="localMedia.mic"/>
          </b-form-checkbox>
        </li>
      </ul>
      <div v-if="manageAV" class="local-media-av">
        <AVSettings :media="media" @on-close="manageAV = false" @on-av-change="onAVChange"/>
      </div>
    </div>
    <div v-if="!manageAV" class="stream-states">
      <BIconCameraVideoOff class="camera-off" v-if="!localMedia.camera"/>
    </div>
    <video @click="toggleControlsOnMobile(true)" @mouseover="toggleControlsOnDesktop(true)" ref="localMedia" :class="localMediaClass" muted autoplay playsInline></video>
  </div>
</template>

<script>
import {
  BIconCameraVideoOff,BIconMicMute,BIconTelephoneFill,BIconCameraVideo,BIconMic,BIconThreeDotsVertical,
  BIconFullscreen,BIconDisplay,BIconFullscreenExit,BIconX,BIconPip,BIconBack,BIconFront
} from 'bootstrap-vue';

import AVSettings from './AVSettings';

export default {
  props: ['activeCall','platform'],
  components: {
    BIconCameraVideoOff,BIconCameraVideo,BIconMicMute, BIconMic,BIconTelephoneFill,BIconThreeDotsVertical,
    BIconFullscreen,BIconDisplay,BIconFullscreenExit,BIconX,BIconPip,BIconBack,BIconFront,
    AVSettings
  },
  data(){
    return {
      manageAV: false,
      localMedia: {
        camera: true,
        mic: true,
        fullscreen: false,
        screenshare: false,
        blur: false,
        facingMode: 'user',
        platform: {
          type: this.platform,
          orientation: null
        },
      },
      media: null,
      showControls: false,
      localMediaClass: "local-media video-shadow"
    };
  },

  methods: {
    toggleControlsOnDesktop(state){
      if(this.platform !== "desktop"){
        return;
      }

      this.showControls = state;
    },
    toggleControlsOnMobile(state){
      if(this.platform === "desktop"){
        return;
      }

      this.showControls = state;
    },
    setOrientation(){
      this.localMedia.platform.orientation = window.innerWidth > window.innerHeight ? "landscape" : "portrait";
      this.$callstr.emitLocalMediaChanges(this.localMedia);
    },
    minimize(){
      this.$emit('on-action',{event: "minimize"});
    },

    toggleCamera(state){
      return this.media.camera.toggle(state).then((newStates) => {
        this.localMedia.facingMode = newStates.facingMode;
        this.localMedia.camera = newStates.camera;
        this.localMedia.mic = newStates.mic;
        this.$callstr.emitLocalMediaChanges(this.localMedia);
      });
    },

    toggleMic(state){
      return this.media.mic.toggle(state).then((newState) => {
        this.localMedia.mic = newState;
        this.$callstr.emitLocalMediaChanges(this.localMedia);
      });
    },

    toggleScreenshare(state){
      return (state ? this.media.screen.startShare() : this.media.screen.stopShare()).then(newState => {
        this.localMedia.screenshare = newState.screenshare;
        this.localMedia.camera = newState.camera;
        this.localMedia.facingMode = newState.facingMode;
        this.localMedia.mic = newState.mic;
        this.$callstr.emitLocalMediaChanges(this.localMedia);

        if(state){
          this.localMediaClass = "local-media-screenshare video-shadow";
          this.media.screen.on('ended', (newState) => {
            this.localMedia.screenshare = newState.screenshare;
            this.localMedia.camera = newState.camera;
            this.localMedia.facingMode = newState.facingMode;
            this.localMedia.mic = newState.mic;
            this.$callstr.emitLocalMediaChanges(this.localMedia);
            this.localMediaClass = "local-media video-shadow";
          })
        }
        else{
          this.localMediaClass = "local-media video-shadow";
        }
      });
    },

    toggleFullscreen(state){
      this.localMedia.fullscreen = state;
      this.$emit('on-action',{event: "fullscreen", data: {state}})

      if(state){
        let onFullscreenClose = () => {
          if (!document.fullscreenElement) {
            this.localMedia.fullscreen = false;
            this.$emit('on-action',{event: "fullscreen", data: {state: false, fromEventListener: true}})
            document.removeEventListener('fullscreenchange', onFullscreenClose, true);
          }
        };
        document.addEventListener('fullscreenchange', onFullscreenClose, true);
      }
    },

    flipCamera(newMode){
      return this.media.camera.flipCamera(newMode).then((newStates) => {
        this.localMedia.facingMode = newStates.facingMode;
        this.localMedia.camera = newStates.camera;
      })
    },

    hangup(){
      this.$callstr.hangup(this.activeCall.client.socketId).then(() => {
        this.$callstr.closeStreams().then(() => {
          this.$root.$emit('onAgentEvent',{event: "hangup-made"});
        });
      });
    },

    onAVChange(){
      this.media.changeSource().then(newState => {
        this.localMedia.camera = newState.camera;
        this.localMedia.mic = newState.mic;
      });
    }
  },

  mounted(){
    this.$api.get('ice')
    .then(res => {
      this.$callstr.setIceServers(res.data);
      this.media = this.$callstr.setLocalMedia(this.$refs['localMedia']);
      this.media.init({
        camera: this.localMedia.camera,
        mic: this.localMedia.mic
      })
      .then(() => {
        switch(this.activeCall.type){
          case "answer":
          this.$callstr.answer(this.activeCall.client, {
            initiator: true
          })
          .then(() => {
            this.setOrientation();
            window.addEventListener('resize', this.setOrientation);

            this.$root.$emit('onAgentEvent',{event: "answer-made"});

            this.$callstr.signal.off('hangup-made');
            this.$callstr.signal.on('hangup-made', () => {
              this.$callstr.closeStreams().then(() => {
                this.$root.$emit('onAgentEvent',{event: "hangup-received"});
              });
            });
          });
          break;
        }
      })
    })
  },

  beforeDestroy(){
    window.removeEventListener('resize', this.setOrientation, true);
  }
};
</script>
