<template>
  <div class="av-settings text-white">
    <b-button variant="link" style="position: absolute; top: 0; right: 0;" @click="closeAV()"><BIconX class="text-white" font-scale="2"/></b-button>
    <div>Camera</div>
    <b-dropdown block v-if="videoDevices" class="av-devices-dropdown mb-3" id="dropdown-videoDevices" size="sm" variant="light">
        <template v-slot:button-content>
            {{ selected.videoDevice ? selected.videoDevice.label : "Not Defined" }}
        </template>
        <b-dropdown-item
            v-for="item in videoDevices"
            :key="item.id"
            :active="selected.videoDevice && selected.videoDevice.deviceId === item.deviceId"
            @click="setDevice('videoDevice', item)"
            >
            {{ item.label }}
        </b-dropdown-item>
    </b-dropdown>
    <div>Microphone</div>
    <b-dropdown block v-if="audioInputDevices" class="av-devices-dropdown mb-3" id="dropdown-audioInputDevices" size="sm" variant="light">
        <template v-slot:button-content>
            {{ selected.audioInputDevice ? selected.audioInputDevice.label : "Not Defined" }}
        </template>
        <b-dropdown-item
            v-for="item in audioInputDevices"
            :key="item.id"
            :active="selected.audioInputDevice && selected.audioInputDevice.deviceId === item.deviceId"
            @click="setDevice('audioInputDevice', item)"
            >
            {{ item.label }}
        </b-dropdown-item>
    </b-dropdown>
    <div>Speaker</div>
    <b-dropdown block dropup v-if="audioOutputDevices" class="av-devices-dropdown mb-3" id="dropdown-audioOutputDevices" size="sm" variant="light">
        <template v-slot:button-content>
            {{ selected.audioOutputDevice ? selected.audioOutputDevice.label : "Not Defined" }}
        </template>
        <b-dropdown-item
            v-for="item in audioOutputDevices"
            :key="item.id"
            :active="selected.audioOutputDevice && selected.audioOutputDevice.deviceId === item.deviceId"
            @click="setDevice('audioOutputDevice', item)"
            >
            {{ item.label }}
        </b-dropdown-item>
    </b-dropdown>
    <b-button variant="primary" size="sm" @click="saveDevices()">Save</b-button>
  </div>
</template>

<script>
import {BIconX} from 'bootstrap-vue';
export default {
  props: ['media'],
  components: {
    BIconX
  },
  data(){
    return {
      videoDevices: [],
      audioInputDevices: [],
      audioOutputDevices: [],
      selected: {
          videoDevice: null,
          audioInputDevice: null,
          audioOutputDevice: null,
      },
    };
  },

  methods: {
    getDevices(){
      navigator.mediaDevices.enumerateDevices().then(devices => {
          this.videoDevices = devices.filter(d => d.kind === "videoinput").map(item => {
              return {
                  deviceId: item.deviceId,
                  label: item.label
              };
          });

          this.audioInputDevices = devices.filter(d => d.kind === "audioinput").map(item => {
              return {
                  deviceId: item.deviceId,
                  label: item.label
              };
          });

          this.audioOutputDevices = devices.filter(d => d.kind === "audiooutput").map(item => {
              return {
                  deviceId: item.deviceId,
                  label: item.label
              };
          });

          let savedDevices = this.media.getAVDevices();
          if(savedDevices){
            if(savedDevices.videoDevice === null){
              savedDevices.videoDevice = this.videoDevices[0];
            }

            if(savedDevices.audioInputDevice === null){
              savedDevices.audioInputDevice = this.audioInputDevices[0];
            }

            if(savedDevices.audioOutputDevice === null){
              savedDevices.audioOutputDevice = this.audioOutputDevices[0];
            }

            this.selected = savedDevices;
          }
          else{
              this.selected.videoDevice = this.videoDevices[0];
              this.selected.audioInputDevice = this.audioInputDevices[0];
              this.selected.audioOutputDevice = this.audioOutputDevices[0];
          }
      });
    },
    closeAV(){
      this.$emit('on-close');
    },

    setDevice(type, item){
      this.selected[type] = item;
    },

    saveDevices(){
      this.media.saveAVDevices(this.selected);
      this.$emit('on-av-change', {devices: this.selected});
      this.closeAV();
    }
  },

  mounted(){
    this.getDevices()
  }
};
</script>
